import fetch from "../fetch";
export async function publishCar(listId) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const response = await fetch("/car-registration-publish", {
    method: "POST",
    headers,
    body: JSON.stringify({ listId }),
    credentials: "include",
  });

  const jsonData = await response.json();

  return await {
    status: jsonData?.status,
    errorMessage: null,
  };
}
