import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EmailSignature extends Component {
    static propTypes = {
        siteName: PropTypes.string,
    }
    render() {
        return (
            <div>
                Thank you, <br />
                Thor Rentals
            </div>
        )
    }
}

export default EmailSignature;