import React, { Component } from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import EmptySpace from "../modules/EmptySpace";
import EmailSignature from "./EmailSignature";
import { COMMON_TEXT_COLOR } from "../../../constants/index";
import moment from "moment";

class BookingConfirmationAccountant extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostSSN: PropTypes.string,
      hostName: PropTypes.string,
      hostEmail: PropTypes.string,
      hostPassportNumber: PropTypes.string,
      hostPhoneNumber: PropTypes.string,
      guestSSN: PropTypes.string,
      guestName: PropTypes.string,
      gusetEmail: PropTypes.string,
      guestPassportNumber: PropTypes.string,
      guestPhoneNumber: PropTypes.string,
      resavationCreated: PropTypes.string,
      vehicleRegistrationPlate: PropTypes.string,
      insuranceCompanyName: PropTypes.string,
      checkIn: PropTypes.string,
      checkOut: PropTypes.string,
      licenseNumber: PropTypes.string,
    }),
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const {
      content: {
        hostSSN,
        hostName,
        hostEmail,
        hostPhoneNumber,
        guestSSN,
        guestName,
        gusetEmail,
        guestPassportNumber,
        guestPhoneNumber,
        resavationCreated,
        vehicleRegistrationPlate,
        checkIn,
        checkOut,
        licenseNumber,
      },
    } = this.props;

    return (
      <Layout>
        <Header
          color="rgb(255, 90, 95)"
          backgroundColor="#F7F7F7"
          siteName={"Thor Rentals"}
        />

        <Body textStyle={textStyle}>
          <div>{`This booking was created at ${moment(resavationCreated).format(
            "LLL"
          )}`}</div>
          <EmptySpace height={20} />
          <div>Owner:</div>
          <div>{`Vehicle Registration Number: ${vehicleRegistrationPlate}`}</div>
          <div>{`Name: ${hostName}`}</div>
          <div>{`Social Security Number: ${hostSSN}`}</div>
          <div>{`Phone: ${hostPhoneNumber}`}</div>
          <div>{`Email: ${hostEmail}`}</div>
          <EmptySpace height={20} />

          <div>Booking:</div>
          <div>{`Pickup time: ${moment(checkIn).format("LLL")}`}</div>
          <div>{`Drop off time: ${moment(checkOut).format("LLL")}`}</div>
          <EmptySpace height={20} />
          <div>Renter:</div>
          <div>{`Renter name: ${guestName}`}</div>
          <div>{`Renter Social Security Number: ${guestSSN}`}</div>
          <div>{`Driver license number: ${licenseNumber}`}</div>
          <div>{`Passport Number: ${guestPassportNumber}`}</div>
          <div>{`Phone: ${guestPhoneNumber}`}</div>
          <div>{`Email: ${gusetEmail}`}</div>
          <EmailSignature />
        </Body>
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingConfirmationAccountant;
