import fetch from "../fetch";

export async function getPdf() {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const response = await fetch("/pdf", {
    headers,
    credentials: "include",
  });

  const jsonData = await response.json();

  if (jsonData?.status == 200) {
    return await { ...jsonData };
  } else if (jsonData?.status == 500) {
    return await {
      status: 500,
      errorMessage: jsonData.errorMessage,
    };
  } else {
    return await {
      status: 400,
      errorMessage: jsonData.errorMessage ?? null,
    };
  }
  
}
