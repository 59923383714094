import React from "react";
import cx from "classnames";
import cs from "../commonStyle.css";
import { getPdf } from "../../core/pdf/getPdf";
import printJS from "print-js";

export default function HandleDownloadPDF({ text, preText, postText }) {
  const handlePrint = (downloadUrl) => {
    printJS({
      printable: downloadUrl?.split(",")?.[1] ?? "",
      type: "pdf",
      base64: true,
    });
  };

  const downloadPdf = async () => {
    const pdf = await getPdf();
    handlePrint(pdf?.data);
  };

  const handleClick = () => {
    downloadPdf();
  };

  return (
    <>
      <span>
        {preText ??
          "Upon receiving a car you and the car owner need to sign the"}
      </span>{" "}
      <button
        style={{
          background: "transparent",
          border: 0,
        }}
        type="button"
        onClick={handleClick}
      >
        <span className={cx(cs.siteLinkColor, cs.commomLinkborderBottom)}>
          {" "}
          {text ?? "Download pdf"}
        </span>
      </button>
      <span>{postText ?? "please print itout and have it with you."}</span>
    </>
  );
}
