import React from "react";
import { FormControl } from "react-bootstrap";
import { injectIntl } from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./CommonFormComponent.css";

class CustomFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
  }

  componentDidMount = () => {
    // To set the value from the parent use inputValue
    if (this.props.inputValue) {
      this.setState({ inputValue: this.props.inputValue });
    }
  };

  render() {
    const {
      input,
      label,
      inputClass,
      meta: { touched, error },
      type,
      componentClass,
      maxLength,
      readOnly,
    } = this.props;
    const { inputValue } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <FormControl
          {...input}
          value={inputValue}
          placeholder={label}
          componentClass={componentClass}
          type={type}
          readOnly={readOnly}
          className={inputClass}
          maxLength={maxLength}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(CustomFormComponent));
