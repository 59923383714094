export const ReservationStates = Object.freeze({
  APPROVED: Symbol("approved"),
  COMPLETED: Symbol("completed"),
  CANCELLED: Symbol("cancelled"),
  DECLINED: Symbol("declined"),
  EXPIRED: Symbol("expired"),
  PENDING: Symbol("pending"),
});

export const PaymentState = Object.freeze({
  PAYMENT_FAILED: Symbol("PAYMENT_FAILED"),
  PAYMENT_COMPLETED: Symbol("PAYMENT_COMPLETED"),
  PAYMENT_SUCCEEDED: Symbol("PAYMENT_SUCCEEDED"),
});

export const PaymentStatus = Object.freeze({
  ERR: Symbol("ERR"),
  CLO: Symbol("CLO"),
  ACT: Symbol("ACT"),
});

export const rapydPayPath = "/rapyd-pay";
export const rapydWebhookPath = "/rapyd-webhook";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
